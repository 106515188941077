import { t } from '@lingui/macro'

/**
  @doc https://doc.nbttfc365.com/docs/front-end/fe-feature/agent/%E4%BB%A3%E7%90%86%E5%95%86%E6%96%87%E6%A1%A3
*/
export const agentModuleRoutes = {
  agencyCenter: '/agent/agency-center',
  gains: '/agent/gains',
  invite: '/agent/invite-analytics',
  inviteCheckMore: '/agent/invitation',
  inviteCheckNew: '/agent/invitation-v3',
}

export enum EmailOrPhoneEnum {
  /** 手机号 */
  phone = 1,
  /** 邮箱 */
  email = 2,
}

/** 申请代理商申请状态枚举 */
export enum AgentApplicationEnum {
  /** 未申请 */
  NotApplied = -1,
  /** 待审核 */
  ToBeReviewed = 0,
  /** 申请通过 */
  ApplicationApproved = 1,
  /** 申请未通过 */
  Failed = 2,
}

export type AuditStatusType = {
  /** 审核状态 */
  status: AgentApplicationEnum

  /** 拒绝理由 */
  rejectReason: string
}

export const agentInviteTotalListOptions = {
  totalNum: 'num',
  agentNum: 'agentNum',
}

export enum AgentChartKeyEnum {
  TotalIncomes = 'totalIcomes',
  IncomeAnalysis = 'incomeAnalysis',
  InvitedList = 'invitedList',
  TotalInvitedList = 'totalInvitedList',
}

enum DateOptionsTypes {
  custom,
  now,
  last7Days,
  last30Days,
  all,
}

const infoHeaderTypes = () => {
  return {
    [DateOptionsTypes.now]: {
      title: t`constants_agent_index_5101592`,
      content: t`constants_agent_index_5101593`,
    },
    [DateOptionsTypes.last7Days]: {
      title: t`constants_agent_index_5101594`,
      content: t`constants_agent_index_5101595`,
    },
    [DateOptionsTypes.last30Days]: {
      title: t`constants_agent_index_5101596`,
      content: t`constants_agent_index_5101597`,
    },
  }
}

const totalIncomeChartDefaultProperties = {
  color: '#F1AE3D',
}

const incomeAnalysisChartDefaultProperties = [
  { color: '#6195F6' },
  { color: '#61DEF6' },
  { color: '#61C1F6' },
  { color: '#008080' },
  { color: '#5D3FD3' },
]

const pieChartDefaultProperties = [
  { color: '#6195F6' },
  { color: '#61DEF6' },
  { color: '#61C1F6' },
  { color: '#008080' },
  { color: '#5D3FD3' },
]

export {
  DateOptionsTypes,
  infoHeaderTypes,
  totalIncomeChartDefaultProperties,
  incomeAnalysisChartDefaultProperties,
  pieChartDefaultProperties,
}
